import { Accordion, Button, Form, Table } from "react-bootstrap";
import { EProdutoTipo, ICardapio, IProduto } from "../../Models/cardapio";
import { IPedidoItem, IPedidoItems } from "../../Models/pedido";
import { DeepPartial } from "../../Models/genericInterfaces";
import styles from './styles.module.scss';
import { FaMinus, FaPlus } from "react-icons/fa";
import { useCallback, useEffect, useMemo, useState } from "react";
import useMask from "../../Hooks/utils/useMask";

interface Props {
    Composicao: ICardapio;
    ProdutoComposicoes: DeepPartial<IPedidoItem|undefined>;
    setProdutoComposicoes: React.Dispatch<React.SetStateAction<DeepPartial<IPedidoItem>>>;
    Adicional: boolean;
}

const ComposicaoCheck = ({Composicao, ProdutoComposicoes, setProdutoComposicoes, Adicional} : Props) => {
    const { getMinMax, setMaskMoney } = useMask();
    const [composicaoLimite, setComposicaoLimite] = useState<boolean>(false);

    const NaComposicao = useCallback((Ingrediente: IProduto): boolean => {
        if (ProdutoComposicoes && ProdutoComposicoes.COMPLEMENTOS && 
            (ProdutoComposicoes.COMPLEMENTOS as IPedidoItems).some((Item) =>
                Item.CD_PRODUTO === Ingrediente.CD_PRODUTO
            )
        ) {
            return true
        } else {
            return false
        }
    }, [ProdutoComposicoes]);

    const ProdutoNaComposicao = useCallback((Ingrediente: IProduto) : IPedidoItem => {
        return (ProdutoComposicoes && ProdutoComposicoes.COMPLEMENTOS && 
            (ProdutoComposicoes.COMPLEMENTOS as IPedidoItems).find((Item) =>
                Item.CD_PRODUTO === Ingrediente.CD_PRODUTO
            )) as IPedidoItem
    }, [ProdutoComposicoes]);

    const ControleComposicao = useCallback((Ingrediente: IProduto, Quantidade: number, Incremental: boolean = false) => {
        setProdutoComposicoes((prev) => {
            const COMPLEMENTOS = [...prev?.COMPLEMENTOS as IPedidoItems];
            const index = COMPLEMENTOS.findIndex((item) => item.CD_PRODUTO === Ingrediente.CD_PRODUTO);
        
            if (Incremental) {
                if (index >= 0) {
                    // Se já estiver na composição, apenas atualize a quantidade - Crie um novo objeto e substitua o existente
                    const novaQuantidade : number = getMinMax((Ingrediente.MIN ?? 0), (Ingrediente.MAX ?? 1), COMPLEMENTOS[index].QTD_PRODUTO + Quantidade);

                    if (novaQuantidade > 0) {
                        if (!composicaoLimite || Quantidade < 0) {
                            COMPLEMENTOS[index] = {
                                ...COMPLEMENTOS[index],
                                QTD_PRODUTO: novaQuantidade
                            };
                        };
                    } else {
                        COMPLEMENTOS.splice(index, 1);
                    }
                } else {
                    // Caso contrário, adicione o novo complemento
                    if (Quantidade > 0 && !composicaoLimite) {
                        COMPLEMENTOS.push({
                            OFF_NOME: Ingrediente.NOME,
                            CD_PRODUTO: Number(Ingrediente.CD_PRODUTO),
                            QTD_PRODUTO: Number(Quantidade),
                            CD_COMPLEMENTO: Composicao.CD_CATEGORIA,
                            VLRUN_PRODUTO: Adicional ? Ingrediente.VALOR : 0
                        } as IPedidoItem);
                    }
                }
            } else { // Caso não seja incremental, remova ou adicione o complemento
                if (index >= 0) {
                    COMPLEMENTOS.splice(index, 1);
                } else {
                    if (!composicaoLimite) {
                        COMPLEMENTOS.push({
                            OFF_NOME: Ingrediente.NOME,
                            CD_PRODUTO: Number(Ingrediente.CD_PRODUTO),
                            QTD_PRODUTO: Number(Quantidade),
                            CD_COMPLEMENTO: Composicao.CD_CATEGORIA,
                            VLRUN_PRODUTO: Adicional ? Ingrediente.VALOR : 0
                        } as IPedidoItem);
                    }
                }
        
                return { 
                    ...prev,
                    VLRUN_PRODUTO: Number((ProdutoComposicoes?.OFF_VLRUN ?? 0) + COMPLEMENTOS.reduce((Soma, Item) => Number(Soma) + Number(Item.VLRUN_PRODUTO), 0)), 
                    COMPLEMENTOS: COMPLEMENTOS 
                };
            }
        
            return { 
                ...prev, 
                VLRUN_PRODUTO: Number((ProdutoComposicoes?.OFF_VLRUN ?? 0) + COMPLEMENTOS.reduce((Soma, Item) => Number(Soma) + Number(Item.VLRUN_PRODUTO), 0)),
                COMPLEMENTOS: COMPLEMENTOS 
            };
        });
    }, [setProdutoComposicoes, getMinMax, Composicao.CD_CATEGORIA, composicaoLimite, Adicional, ProdutoComposicoes?.OFF_VLRUN]);

    useEffect(() => { // Carrega os ingredientes que tem qtd minima maior que zero
        Composicao.INGREDIENTES?.forEach((Ingrediente) => {
            if ((Ingrediente.MIN ?? 0) > 0) {
                ControleComposicao(Ingrediente, (Ingrediente.MIN ?? 1), true);
            }
        }, [Composicao.INGREDIENTES, ControleComposicao]); // O array vazio indica que o efeito só será executado após a montagem inicial
    }, [Composicao.INGREDIENTES]); //eslint-disable-line

    useEffect(() => {
        let Somatorio: number = 0;
        (ProdutoComposicoes?.COMPLEMENTOS as IPedidoItems).map((Item) => Item.CD_COMPLEMENTO === Composicao.CD_CATEGORIA ? Somatorio = Somatorio + (Item.QTD_PRODUTO ?? 0) : null)

        if (Composicao.MAX && Composicao.MAX > 0) {
            setComposicaoLimite(Somatorio >= Composicao.MAX)
        } else {
            setComposicaoLimite(false)
        }
    }, [ProdutoComposicoes?.COMPLEMENTOS, Composicao.MAX, Composicao.CD_CATEGORIA]);

    
    const sortedIngredientes = useMemo(() => {
        return Composicao[Adicional ? 'PRODUTOS' : 'INGREDIENTES']?.slice().sort((a, b) => {
            return a.NOME.localeCompare(b.NOME);  // Ordena por ordem alfabética da propriedade 'NOME'
        });
    }, [Composicao, Adicional]);
    

    const tabela = useMemo(() => {
        return (
            <Table striped bordered hover className='tabela'>
                <thead>
                    {
                        Adicional ?
                            <></>
                        :
                            <tr>
                                <td className="d-flex align-content-center" style={{backgroundColor: 'white'}}>
                                    <div style={{fontWeight:'bold', zoom:'1.1'}}>{Adicional ? 'Adicionais' : Composicao.NOME}</div>
                                    <div className="ms-2 d-flex align-items-center" style={{fontWeight:'lighter', fontStyle:'italic', fontSize:'0.85rem'}}>{(Composicao.MIN ?? 0) > 0 ? `(Min: ${Composicao.MIN})` : ''}</div>
                                    <div className="ms-2 d-flex align-items-center" style={{fontWeight:'lighter', fontStyle:'italic', fontSize:'0.85rem'}}>{(Composicao.MAX ?? 0) > 0 ? `(Máx: ${Composicao.MAX})` : ''}</div>
                                </td>
                            </tr>
                    }
                </thead>
                <tbody>
                    {
                        sortedIngredientes?.map((Ingrediente) => {
                            const itemNaComposicao : boolean = NaComposicao(Ingrediente);
                            const quantidadeItem : number = getMinMax((Ingrediente.MIN ?? 0), (Ingrediente.MAX ?? 0), ProdutoNaComposicao(Ingrediente)?.QTD_PRODUTO ?? 0);
                            const styleItemIndisponivel = Ingrediente.DISPONIVEL ? {} : {opacity: '0.5', textDecoration: 'line-through'};
                            const styleComposicaoDesabilitado = !composicaoLimite || itemNaComposicao ? {} : {opacity: '0.5'};
        
                            if (!Ingrediente.ATIVO || !Ingrediente.DISPONIVEL) {
                                return null;
                            };
        
                            if (Adicional && Composicao.COMPOSICAO) {
                                const naoEstaNaComposicao = !Composicao.COMPOSICAO?.some((ComposicaoItem) =>
                                    ComposicaoItem.INGREDIENTES?.some((ComposicaoItemIngrediente) =>
                                        ComposicaoItemIngrediente.CD_PRODUTO === Ingrediente.CD_PRODUTO
                                    )
                                );
        
                                if (!naoEstaNaComposicao || Ingrediente.TIPO === EProdutoTipo.Produto) {
                                    return null;
                                };
                            };                        

                            return (
                                <tr key={`${Composicao.CD_CATEGORIA}-${Ingrediente.CD_PRODUTO}-tr`}>
                                    <td 
                                        className="d-flex align-items-center"
                                        onClick={() => Ingrediente.DISPONIVEL && ((Ingrediente.MAX ?? 1) === 1) ? ControleComposicao(Ingrediente, 1) : null}
                                    >
                                        <Form.Check
                                            disabled
                                            className={styles.customCheckbox}
                                            style={{zoom: '1.3', ...styleItemIndisponivel, ...styleComposicaoDesabilitado}}
                                            checked={itemNaComposicao}
                                            id={`${Composicao.CD_CATEGORIA}-${Ingrediente.CD_PRODUTO}`}
                                            onChange={() => Ingrediente.DISPONIVEL && ((Ingrediente.MAX ?? 1) === 1) ? ControleComposicao(Ingrediente, 1) : null}
                                        />

                                        <label 
                                            htmlFor={`${Composicao.CD_CATEGORIA}-${Ingrediente.CD_PRODUTO}`} 
                                            style={{zoom: '1.1', ...styleItemIndisponivel, ...styleComposicaoDesabilitado}}
                                        >
                                            {`${Ingrediente.NOME} ${Adicional ? setMaskMoney(Ingrediente.VALOR, 'R$', ',') : ''}`}
                                        </label>
                                        
                                        {
                                            Ingrediente.DISPONIVEL && ((Ingrediente.MAX ?? 1) > 1) ?
                                                <div className="w-100 d-flex justify-content-end">
                                                    <Button
                                                        disabled={composicaoLimite && (quantidadeItem < 1)}
                                                        className="mx-2"
                                                        size="sm"
                                                        variant={"primary"}
                                                        onClick={(e: any) => {
                                                            ControleComposicao(Ingrediente, -1, true);
                                                        }}
                                                    >
                                                        <FaMinus />
                                                    </Button>
                                                    <div style={{fontSize: '1.3rem'}}>
                                                        {
                                                            quantidadeItem
                                                        }
                                                    </div>
                                                    <Button
                                                        disabled={composicaoLimite}
                                                        className="mx-2"
                                                        size="sm"
                                                        variant={"primary"}
                                                        onMouseUp={(e: any) => {
                                                            ControleComposicao(Ingrediente, 1, true);
                                                        }}
                                                    >
                                                        <FaPlus />
                                                    </Button>
                                                </div>
                                            :
                                                null
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>
        )
    }, [Adicional, Composicao, ControleComposicao, NaComposicao, ProdutoNaComposicao, composicaoLimite, getMinMax, setMaskMoney])

    const verificaExistenciaAdicional = () => {
        if (Adicional) {
            const adicionais = Composicao.PRODUTOS?.map((Ingrediente) => { //eslint-disable-line
                if (Adicional && Composicao.COMPOSICAO) {
                    const naoEstaNaComposicao = !Composicao.COMPOSICAO?.some((ComposicaoItem) =>
                        ComposicaoItem.INGREDIENTES?.some((ComposicaoItemIngrediente) =>
                            ComposicaoItemIngrediente.CD_PRODUTO === Ingrediente.CD_PRODUTO
                        )
                    );
        
                    if (!naoEstaNaComposicao || Ingrediente.TIPO === EProdutoTipo.Produto) {
                        return false
                    } else {
                        return true
                    }
                }; 
            })

            if (adicionais?.find((a) => a === true)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }
    }

    return (
        <>
            {
                Adicional && verificaExistenciaAdicional() ?
                    (Composicao.PRODUTOS?.length ?? 0) > 1 ?
                        <Accordion className="mt-3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><div style={{fontWeight: 'bold', zoom: '1.1'}}>Adicionais</div></Accordion.Header>
                                <Accordion.Body>
                                    {tabela}
                                </Accordion.Body>
                                </Accordion.Item>
                        </Accordion>
                    :
                        null
                :
                    tabela
            }
        </>
    )
};

// -> Validações do Ingrediente
// X Se estiver indisponivel, deixar somente texto grifado
// X Caso a quantidade máxima do ingrediente for maior que 1, colocar botão de + e - em vez do checkbox
// X Caso a quantidade máxima do ingrediente for apenas 1, deixar checkbox
// X Validar quantidade mínima e máxima
// X Caso a quantidade máxima for 0, não validar quantidade máxima (Deixei 0 e 1 como se fosse maximo 1)
// -> Validações da Composição
// X Somar todos os itens selecionados e validar se está ultrapassando a quantidade minima ou máxima
// X Caso a quantidade máxima da composição for 0, não validar a quantidade máxima
// -> Composição Adicional
// X Criar tabela com os adicionais com custo do que estiver fora do array da composição (composicao.produtos, filtrar o que ta repetido na composição e o que não for o próprio código do produto)

export default ComposicaoCheck;