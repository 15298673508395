import usePedidoUtil from "../../Hooks/Pedido/util";
import useMask from "../../Hooks/utils/useMask";
import { IMesa } from "../../Models/mesa";
import { IPedidoItem } from "../../Models/pedido";

const ImpressaoMesa = (mesa: IMesa, Colunas: number) => {
    const { setMaskMoney, setDate } = useMask();
    const pedidoUtil = usePedidoUtil();
    const _margem = 10;
    const SIZE = (TamCampo: number) : number => {
        return Math.trunc((TamCampo * (Colunas - _margem)) / 100)
    }
    const BREAK_ITEM = (Texto: string, TamMax: number = 70) : string => {
        const maxsize = SIZE(TamMax);
        let text = "";
    
        if (Texto && Texto !== "") {
            for (let i = 0; i < Texto.length; i++) {
                if (i > 0 && i % maxsize === 0) {
                    text += `\n| ${' '.padStart(SIZE(10), ' ')} | ${Texto.slice(i - maxsize, i).padEnd(maxsize, ' ')} | ${' '.padStart(SIZE(20), ' ')} |`;
                }
            }
            // Adiciona o restante do texto, se houver
            if (Texto.length % maxsize !== 0) {
                text += `\n| ${' '.padStart(SIZE(10), ' ')} | ${Texto.slice(Texto.length - (Texto.length % maxsize)).padEnd(maxsize, ' ')} | ${' '.padStart(SIZE(20), ' ')} |`;
            }
        } else {
            text = Texto;
        }
        return text;
    };

    const cabecalho =
`${' '.padEnd(SIZE(30), ' ')}${`**** FROTA DELIVERY ****`}

${`Mesa: ${mesa.NR_MESA}`.padStart((_margem), ' ')}
`


//-------------------------------INICIO ITENS---------------------------------------------------
    const itens =
`
${`${setDate(new Date(mesa.DT_ABERTURA!), {day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"}, false, false)}`}
${
mesa.PEDIDOS.length > 0
? mesa.PEDIDOS.map((Pedido) => {
return (
`${`\nPedido: #${Pedido.CD_PEDIDO}`}
${`Status: ${Pedido.STATUS === 'C'? 'CANCELADO' : Pedido.STATUS === 'A' ? 'EM PRODUÇÃO' : 'FINALIZADO'}`}
__${'_'.padEnd(SIZE(10), '_')}__${'_'.padEnd(SIZE(70), '_')}__${'_'.padEnd(SIZE(7), '_')}___
| ${' '.padEnd(SIZE(98), ' ')}|
| ${' '.padEnd(SIZE(45), ' ')}${'ITENS'.padEnd(SIZE(35), ' ')}${' '.padEnd(SIZE(19), ' ')} |
|_${'_'.padEnd(SIZE(10), '_')}___${'_'.padEnd(SIZE(70), '_')}__${'_'.padEnd(SIZE(7), '_')}_|
${Pedido.ITENS
? Pedido.ITENS.map((item, index) => {
    const complementos = pedidoUtil.agruparComplementosPorComposição([item]); 
    return (
`${index !== 0 ? '\n' : ''}| ${String(item.QTD_PRODUTO).padStart(SIZE(5), ' ')} | ${String(item.NOME).padEnd(SIZE(60), ' ')} | ${setMaskMoney((item.VLRUN_PRODUTO*item.QTD_PRODUTO), 'R$', ',').padStart(SIZE(20), ' ')} |`
+ `${item.VARIACAO ? BREAK_ITEM(item.VARIACAO?.NOME ?? "", 70) : ''}`
+ `${BREAK_ITEM(item.OBS_PRODUTO ?? "", 70)}`
// COMPLEMENTOS
+ `${complementos.length > 0 ? complementos.map((compl) => `\n| ${' '.padStart(SIZE(5), ' ')} | ${`${compl.COMPLEMENTO}:`.padEnd(SIZE(60), ' ')} | ${' '.padStart(SIZE(20), ' ')} |
${compl.ITENS.map((complItem: IPedidoItem, indexComplItem: number) => `${indexComplItem !== 0 ? '\n' : ''}| ${' '.padStart(SIZE(5), ' ')} | ${`(${complItem.QTD_PRODUTO}x) ${complItem.NOME} ${complItem.COMPOSICAO ? '' : setMaskMoney(complItem.VLRUN_PRODUTO, 'R$')}`.padEnd(SIZE(60), ' ')} | ${' '.padStart(SIZE(20), ' ')} |`).join('')}`).join('') : ''}` +
` ${`|${'__'.padEnd(SIZE(10), '_')}_|_${'_'.padEnd(SIZE(60), '_')}_|_${'_'.padEnd(SIZE(20), '_')}_|`}`    
    )
}).join('')
: ''
+'\n'}
|_${'_'.padEnd(SIZE(5), '_')}_|_${'_'.padEnd(SIZE(60), '_')}_|_${'_'.padEnd(SIZE(20), '_')}_|
`
)
})
: ''}
|_${'_'.padEnd(SIZE(5), '_')}___${'_'.padEnd(SIZE(60), '_')}___${'_'.padEnd(SIZE(20), '_')}_|
| ${'*'.padStart(SIZE(5), ' ')}   ${'TOTAL'.padEnd(SIZE(60), '.')}...${setMaskMoney(mesa.VLR_TOTAL ?? 0, 'R$', ',').padStart(SIZE(20), '.')} |
|_${'_____________________________________________'.padEnd(SIZE(20), '_')}|
| ${'*'.padStart(SIZE(5), ' ')}   ${`PAGAMENTO: ${mesa.FORMA_PAGTO ?? '-'}`.padEnd(SIZE(60), '.')}...${setMaskMoney(Number(mesa.VLR_TOTAL ?? 0), 'R$', ',').padStart(SIZE(20), '.')} |
|_${'_'.padEnd(SIZE(5), '_')}___${'_'.padEnd(SIZE(60), '_')}___${'_'.padEnd(SIZE(20), '_')}_|
`
    return {
        cabecalho,
        itens
    }
}

export default ImpressaoMesa;