import { Badge, Button, ButtonGroup, Container, Dropdown, Row } from "react-bootstrap";
import Tabela from "../../Components/tabela";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IUseMesa } from "../../Hooks/Mesa";
import usePedido from "../../Hooks/Pedido";
import FormRodape from "../../Components/formularios/FormRodape";
import { FaArrowLeft, FaPlus, FaPrint } from "react-icons/fa";
import BadgeStatus from "../../Components/formularios/BadgeStatus";
import { useContext } from "react";
import { CtxParams } from "../../Contexts/ctxParams";
import useApp from "../../Hooks/utils/useApp";
import { EStatus } from "../../Models/cadEntrega";
import useVendaPagamento from "../../Hooks/VendaPagamento";
import useMask from "../../Hooks/utils/useMask";
import useReports from "../../Reports";

const MesaCadastro = () => {
    const Mesa = useOutletContext<IUseMesa>();
    const navigate = useNavigate();
    const { GridItens } = usePedido();
    const UserParams = useContext(CtxParams);
    const App = useApp();
    const Pagamento = useVendaPagamento(Mesa.Controller);
    const formatter = useMask();
    const Reports = useReports();

    return (
        <Container className="verticalCenter">
            <div className="w-100">
                <Row>
                    <div className="d-flex justify-content-between">
                        <Badge pill className="d-flex align-items-center" style={{fontSize: '1.3rem'}}>Mesa: {Mesa.Item.NR_MESA}</Badge>
                        {
                            Mesa.Item.STATUS === EStatus.Pendente
                                ? <Button variant="success" onClick={() => Mesa.Controller.Alterar.mutate({CD_MESA: Mesa.Item.CD_MESA, STATUS: EStatus.Andamento})}>Confirmar entrega</Button>
                                : Mesa.Item.STATUS === EStatus.Andamento
                                    ? <Button variant="warning" onClick={() => Pagamento.Modal.Pagamento({CD_MESA: Mesa.Item.CD_MESA, OFF_TOTAL: Mesa.Item.VLR_TOTAL})}>Fechar Conta</Button>
                                    : null
                        }            
                    </div>
                </Row>
                <Row className="mt-3">
                    <div className="d-flex justify-content-center">
                        <Tabela
                            hideHeader
                            colunas={App.Grid}
                            dados={[
                                {
                                    CHAVE: "Data de Abertura",
                                    VALOR: formatter.setDate(new Date(Mesa.Item.DT_ABERTURA), {
                                        day: "2-digit",
                                        weekday: "short",
                                        month: "short",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit"
                                    }, false)
                                },
                                {
                                    CHAVE: "Data de Fechamento",
                                    VALOR: Mesa.Item.DT_FECHAMENTO && formatter.setDate(new Date(Mesa.Item.DT_FECHAMENTO), {
                                        day: "2-digit",
                                        weekday: "short",
                                        month: "short",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit"
                                    }, false)
                                },
                                {
                                    CHAVE: "Status",
                                    VALOR: <BadgeStatus status={Mesa.Item.STATUS} icone={true} tipo="mesa" />
                                },
                                {
                                    CHAVE: "Valor Total",
                                    VALOR: formatter.setMaskMoney(Mesa.Item.VLR_TOTAL ?? 0, 'R$')
                                },
                                {
                                    CHAVE: "Forma de Pagamento",
                                    VALOR: Mesa.Item.FORMA_PAGTO
                                }
                            ]}
                        />
                    </div>
                </Row>
            </div>

            <div className="w-100 mt-3 d-flex justify-content-center flex-wrap">
                <h5>Itens</h5>
                {
                    Mesa.Item.PEDIDOS && Mesa.Item.PEDIDOS.map((pedido) => {
                        return (
                            <div key={pedido.CD_PEDIDO} className="mb-3 w-100">
                                <Row>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5>Pedido {pedido.CD_PEDIDO}</h5>
                                        <BadgeStatus icone={true} status={pedido.STATUS} tipo={'pedido'} />
                                    </div>
                                </Row>                                
                                <Tabela
                                    colunas={GridItens}
                                    dados={pedido.ITENS ? pedido.ITENS : []}
                                />
                            </div>
                        )
                    })
                }
            </div>
            <Row>
            {
                <Dropdown as={ButtonGroup}>
                    <Button variant="dark" onClick={() => 
                    Reports.Print('MESA', Mesa.Item)
                    }
                    >
                        <FaPrint /> Imprimir
                    </Button>
                    <Dropdown.Toggle split variant="dark" id="dropdown-impressao" />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => Reports.Reset()}>Resetar configurações de impressão</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            }
            </Row>

            <FormRodape>
                <Button size="lg" variant="outline-dark" onClick={()=> navigate(-1)}><FaArrowLeft /> Retornar</Button>
                <Button size="lg" variant="success" disabled={Mesa.Item.STATUS === EStatus.Finalizada} onClick={() => navigate(`/cardapio/${UserParams.CD_EMPRESA}/${Mesa.Item.NR_MESA}/false/true`)}><FaPlus /> Pedido</Button>
            </FormRodape>
        </Container>
    );
}

export default MesaCadastro;